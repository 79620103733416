import request from '@/utils/request'

//分销管理--统计查看人数与今日新增查看人数
export function selectLookCount(data) {
  return request({
    url: '/DistributionController/selectLookCount',
    method: 'get',
    params: data
  })
}

//分销管理--查询一栏
export function selectDistribution(data) {
  return request({
    url: '/DistributionController/selectDistribution',
    method: 'post',
    data
  })
}

//分销管理--活动分销
export function selectActivityDistribution(data) {
  return request({
    url: '/DistributionController/selectActivityDistribution',
    method: 'post',
    data
  })
}

//分销管理--需求分销
export function selectDemandDistribution(data) {
  return request({
    url: '/DistributionController/selectDemandDistribution',
    method: 'post',
    data
  })
}

//分销管理--报备分销
export function selectSupplyDistribution(data) {
  return request({
    url: '/DistributionController/selectSupplyDistribution',
    method: 'post',
    data
  })
}

//分销管理--分销用户批量导出
export function exportExcelAll(data) {
  return request({
    url: '/DistributionController/exportExcelAll',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

//分销管理--活动被分销用户批量导出
export function exportExcelActivity(data) {
  return request({
    url: '/DistributionController/exportExcelActivity',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

//分销管理--需求被分销用户批量导出
export function exportExcelDemand(data) {
  return request({
    url: '/DistributionController/exportExcelDemand',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

//分销管理--报备被分销用户批量导出
export function exportExcelSupply(data) {
  return request({
    url: '/DistributionController/exportExcelSupply',
    method: 'post',
    data,
    responseType: 'blob'
  })
}

//分销管理--修改是否联系
export function updateState(data) {
  return request({
    url: '/DistributionController/updateState',
    method: 'get',
    params: data

  })
}

//分销管理--查询未分销该活动或供需的正式会员     -- 活动列表里面的创建分销
export function selectMember(data) {
  return request({
    url: '/DistributionController/selectMember',
    method: 'POST',
    data
  })
}

//分销管理-- 创建分销   
export function saveDistribution(data) {
  return request({
    url: '/DistributionController/saveDistribution',
    method: 'POST',
    data
  })
}

// 分销管理 -- 查询三种类型的分销转换率（0：活动；1：需求；2：报备）
export function selectRate(data) {
  return request({
    url: '/DistributionController/selectRate',
    method: 'get',
    params: data
  })
}

// 分销管理 -- 签到管理
export function selectSignIn(data) {
  return request({
    url: '/DistributionController/selectSignIn',
    method: 'post',
     data
  })
}